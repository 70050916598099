import {getAvarage} from "../utils/calc";
import {getParameterByName} from "../utils/queryString";
import 'element-closest';

let chart = null;
let isPrint = false;
const chartObj = {
    amountInput: document.getElementById("chart-amount"),
    periodInput: document.getElementById("chart-period"),
    percentInput: document.getElementById("chart-percent"),
    moneyUnit: "€",
    yearUnit: "year",
    bottlePrice: 278.75
}
let danishNumberFormat = document.documentElement.lang == "da";

export function createChart(data, customSettings) {

    const settings = {
        axisY: {
            onlyInteger: true
        },
        fullWidth: true,
        lineSmooth: false,
        chartPadding: { right: 40 },
        plugins: [
            Chartist.plugins.comments()
        ]
    };

    if (data.hasOwnProperty('low'))
        settings.low = data.low;

    if (data.hasOwnProperty('high'))
        settings.high = data.high;

    if (RWI.GetScreenDimensions().width < 768) {
        settings.axisX = {
            labelInterpolationFnc: function skipLabels(value, index) {
                return index % 3 === 0 ? value : null;
            }
        }
    }

    new Chartist.Line('#' + data.id, {
        labels: data.labels,
        series: data.series
    }, settings);


    const graphElm = document.getElementById(data.id);
    if (graphElm != null) {
        const sectionElm =  graphElm.closest('section.graph');
        if (sectionElm) {
            const legendList = sectionElm.querySelector('.legend-list');
            const legends = sectionElm.querySelectorAll('.legend-list .legend[data-series-name]');

            // Remove "hidden" class from legend-list
            if (legendList != null) {
                legendList.classList.remove('hidden');
            }

            for (let a = 0; a < legends.length; a++) {
                const legend = legends[a];
                const legendSeriesName = legend.getAttribute('data-series-name');

                // Add clickevents to legends
                legend.addEventListener('click', function () {
                    const ctSeries = Array.from(graphElm.querySelectorAll('.ct-series'));
                    const commentElements = Array.from(graphElm.querySelectorAll('.chartist-comment,.chartist-comment-point'));
                    const currentSeriesElements = ctSeries.concat(commentElements).filter(x => x.getAttribute('ct:series-name') == legendSeriesName);

                    if (legend.classList.contains('inactive')) {
                        legend.classList.remove('inactive');

                        for (let b = 0; b < currentSeriesElements.length; b++) {
                            currentSeriesElements[b].classList.remove('ct-hidden');
                        }
                    } else {
                        legend.classList.add('inactive');

                        for (let b = 0; b < currentSeriesElements.length; b++) {
                            currentSeriesElements[b].classList.add('ct-hidden');
                        }
                    }
                });
            }
        }
    }
}

function pricePrBottle(bottles) {
    let bottlePrice;

    if(bottles < 120) {
        bottlePrice = 1.25;
    } else if(bottles >= 120 && bottles < 240) {
        bottlePrice = 1.15;
    } else if(bottles >= 240 && bottles < 480) {
        bottlePrice = 1.05;
    } else if(bottles >= 480 && bottles < 960) {
        bottlePrice = 1;
    } else if(bottles >= 960 && bottles < 2400) {
        bottlePrice = 0.95;
    } else {
        bottlePrice = 0.9;
    }

    return bottlePrice;
}

function adminCostPercent(amount) {
    let percent;

    if(amount < 80000) {
        percent = 0.004;
    } else if(amount >= 80000 && amount < 160000) {
        percent = 0.003;
    } else {
        percent = 0.002;
    }

    return percent;
}

function storageCostPercent(amount) {
    let storageRate;

    if (amount < 25000) {
        storageRate = 0.01
    } else if(amount < 50000) {
        storageRate = 0.009
    } else if(amount < 100000) {
        storageRate = 0.008
    } else if(amount < 200000) {
        storageRate = 0.007
    } else if(amount < 400000) {
        storageRate = 0.006
    } else {
        storageRate = 0.005
    }
    return storageRate;
}

function salesCommission(amount) {
        let commissionRate;

    if (amount < 25000) {
        commissionRate = 0.1
    } else if(amount < 50000) {
        commissionRate = 0.09
    } else if(amount < 100000) {
        commissionRate = 0.08
    } else if(amount < 200000) {
        commissionRate = 0.07
    } else if(amount < 400000) {
        commissionRate = 0.06
    } else {
        commissionRate = 0.05
    }
    return commissionRate;
}

function numberOfBottles(amount) {
    return Math.round(amount / chartObj.bottlePrice);
}

function formatNumber(num, replaceKommas = true) {
    var formattetNo = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    return replaceKommas ? formattetNo.replace(',', '.') : formattetNo;
}

function calculateMonthInterest(amount, percent) {
    return amount*(1 + percent/100)**(1/12);
}

// seekZero seeks the zero point of the function fn(x), accurate to within x \pm 0.01. fn(x) must be decreasing with x.
function seekZero(fn) {
    var x = 1;
    while (fn(x) > 0) {
        x += 1;
    }
    while (fn(x) < 0) {
        x -= 0.01
    }
    return x + 0.01;
}

// Internal Rate of Return (IRR)
function internalRateReturn(cfs) {
    var depth = cfs.depth;
    var args = cfs.cashFlow;
    var numberOfTries = 1;

    function npv(rate) {
      numberOfTries++;
      if (numberOfTries > depth) {
        throw new Error('internRateReturn function can\'t find a result');
      }
      var rrate = (1 + rate/100);
      var npv = args[0];
      for (var i = 1; i < args.length; i++) {
        npv += (args[i] / Math.pow(rrate, i));
      }
      return npv;
    }

    return Math.round(seekZero(npv) * 100) / 100;
  };


/**
 *
 * @param {Number[]} costPercentArray
 * @param {Number[]} yearlyCosts
 */
function updateKeyNumbers(costPercentArray, yearlyCosts) {

    // Key Figures
    const avaragePercent = Number(getAvarage(costPercentArray) * 100).toFixed(2);
    const costAvaragePercent = danishNumberFormat ? `${avaragePercent.replace('.', ',')} %` : `${avaragePercent} %`;
    document.getElementById("key-figure-1").textContent = chartObj.moneyUnit + " " + formatNumber(chartObj.totalValue);
    document.getElementById("key-figure-2").textContent = costAvaragePercent;
    // Calculation to avarage cost in valuta: chartObj.moneyUnit + " " + formatNumber(Math.round(getAvarage(yearlyCosts)));

    // First Year Costs
    document.getElementById("first-year-costs").textContent = chartObj.moneyUnit + " " + formatNumber(yearlyCosts[0]);
    document.getElementById("cost-percent").textContent = costAvaragePercent;

    // Returns
    const totalReturn = chartObj.totalValue - chartObj.amount;
    const totalProfit = totalReturn - chartObj.costs;
    document.getElementById("value-increase").textContent = chartObj.moneyUnit + " " + formatNumber(totalReturn);
    document.getElementById("sales-commission").textContent = "- " + chartObj.moneyUnit + " " + formatNumber(chartObj.commission);
    document.getElementById("storage-costs").textContent = "- " + chartObj.moneyUnit + " " + formatNumber(chartObj.costs - chartObj.commission);
    document.getElementById("total-profit").textContent = chartObj.moneyUnit + " " + formatNumber(totalProfit);

    // Profits (total and yearly)
    document.getElementById("period-return-percent").textContent = Math.round((totalReturn/chartObj.amount) * 100) + " %";

    const freeCashFlow = [];

    freeCashFlow.push(chartObj.amount * -1);

    for (let i = 1; i < chartObj.years; i++) {
        freeCashFlow.push(0);
    }
    freeCashFlow.push(chartObj.totalValue);

    const yearlyReturnPercent = internalRateReturn({depth: chartObj.amount, cashFlow: freeCashFlow});
    document.getElementById("yearly-return-percent").textContent = danishNumberFormat ? yearlyReturnPercent.toFixed(1).replace('.', ',') + " %" : yearlyReturnPercent.toFixed(1) + " %";

    document.getElementById("period-profit-percent").textContent = Math.round((totalProfit/chartObj.amount) * 100) + " %";
    // Reforming the yearlyCost Array to match the format from Excel Cashflow calculations
    // This is done to easy the Internal Rate Return (IRR) calculation
    let firstCost = yearlyCosts.shift();
    let costWithInvest = firstCost + chartObj.amount;
    yearlyCosts.unshift(costWithInvest);
    // Converts costs to negative value to fit IRR function
    yearlyCosts = yearlyCosts.map(value => value * -1);
    yearlyCosts.push(chartObj.totalValue - chartObj.commission);

    const yearlyProfitPercent = internalRateReturn({depth: chartObj.amount, cashFlow: yearlyCosts});
    document.getElementById("yearly-profit-percent").textContent = danishNumberFormat ? yearlyProfitPercent.toFixed(1).replace('.', ',') + " %" : yearlyProfitPercent.toFixed(1) + " %";

    // let yearlyProfitPercent = internalRateReturn({depth: chartObj.amount, cashFlow: yearlyCosts});

    // document.getElementById("yearly-profit-percent").textContent = danishNumberFormat ? yearlyProfitPercent.toString().replace('.', ',') + " %" : yearlyProfitPercent + " %"

    window.chartObj = chartObj;
}

function generateChartData() {
    const labels = [];
    const amountSeries = [];
    const costsPercent = [];
    const yearlyCosts = [];

    chartObj.amount = parseInt(chartObj.amountInput.value);
    chartObj.years = parseInt(chartObj.periodInput.value);
    chartObj.percent = parseInt(chartObj.percentInput.value);
    chartObj.costs = 0;
    chartObj.totalValue = 0;

    let amount = chartObj.amount;

    //chartObj.bottles = numberOfBottles(amount);
    //let storagePrice = chartObj.bottles * pricePrBottle(chartObj.bottles);
    //storagePrice = Math.round(storagePrice < 60 ? 60 : storagePrice);

    for (let year = 0; year < chartObj.years; year++) {
        labels.push(year);

        /* let adminCost = Math.round(amount * adminCostPercent(amount));
        adminCost = adminCost < 100 ? 100 : adminCost; */
        //const yearCost = storagePrice + adminCost;

        const storageCost = Math.round(storageCostPercent(amount) * amount);
        yearlyCosts.push(storageCost);
        chartObj.costs += storageCost;

        costsPercent.push(storageCost / amount);

        for (let month = 1; month <= 12; month++) {
            const yearX = year + month/12;
            const roundAmount = Math.round(amount * 100) / 100;
            const profit = roundAmount - storageCost/12;
            amountSeries.push({x: yearX, y: profit});
            amount = calculateMonthInterest(amount, chartObj.percent);

            if(month != 12) {
                labels.push('');
            }
        }
    }


    chartObj.commission = Math.round(salesCommission(amount) * amount)
    chartObj.costs += chartObj.commission;

    chartObj.totalValue = Math.round(amount);

    updateKeyNumbers(costsPercent, yearlyCosts);

    return {
        labels,
        series: [amountSeries]
    }
}

function updateChart(data) {
    const chartData = generateChartData();
    const lowestAmount = chartData.series[0][0].y;
    const highestAmount = chartData.series[0][chartData.series[0].length -1].y;

    const settings = {
        axisY: {
            onlyInteger: true,
            labelInterpolationFnc: function addUnit(value, index) {
                let newValue = formatNumber(value);
                return chartObj.moneyUnit + ' ' + newValue;
            }
        },
        axisX: {
            offset: 40,
            labelOffset: {
                x: window.innerWidth < 768 || isPrint ? -10 : -30,
                y: 10
            },
            onlyInteger: true,
            type: Chartist.AutoScaleAxis,
            labelInterpolationFnc: function addUnit(value, index) {
                if(RWI.GetScreenDimensions().width >= 768) {
                    if(value === 0) {
                        return ''
                    } else {
                        return chartObj.yearUnit + ' ' + value.toString();
                    }
                } else {
                    return value.toString();
                }
            }
        },
        chartPadding: {
            right: 20,
            left: 10
        },
        fullWidth: true,
        lineSmooth: true,
        showGrid: true
    };

    if(chartObj.years >= 10) {
        settings.low = lowestAmount >= 50000 ? lowestAmount - 25000 : lowestAmount - 5000;
    } else {
        settings.low = lowestAmount - 5000;
    }

    settings.axisY.offset = window.innerWidth < 1280 ? 60 : 100;

    settings.high = Math.round(highestAmount * 50000) / 50000; // round to nearest 50000

    if(!chart) {
        chart = new Chartist.Line('#' + data.id, chartData, settings);
    } else {
        chart.update(chartData, settings);
    }

    generatePDFlink()
}


export function createChartCalculator(data) {

    chartObj.moneyUnit = chartObj.amountInput.parentNode.querySelector('span').getAttribute('data-unit');
    chartObj.yearUnit = chartObj.periodInput.parentNode.querySelector('span').getAttribute('data-unit');

    chartObj.bottlePrice = data.bottlePrice || chartObj.bottlePrice;

    const amountParam = getParameterByName('amount');
    const periodParam = getParameterByName('years');
    const percentParam = getParameterByName('percent');

    if (amountParam) {
        chartObj.amountInput.value = amountParam;
        let restultTables = document.body.querySelector('.chart__results');
        if(restultTables) {
            restultTables.classList.remove('hidden');
        }
    }
    if (periodParam) {
        chartObj.periodInput.value = periodParam;
    }
    if (percentParam) {
        chartObj.percentInput.value = percentParam;
    }

    const openModalBtns = document.body.querySelectorAll('[data-action="open-modal"]');

    if(!amountParam && openModalBtns) {
        for (let i = 0; i < openModalBtns.length; i++) {
            const openModalBtn = openModalBtns[i];
            const targetSel = openModalBtn.getAttribute("data-target");
            const target = document.getElementById(targetSel);
            const closeBtn = target.querySelector('[aria-label="close"]');

            const toggleModal = (e) => {
                e.preventDefault();

                const ariaHidden = target.getAttribute("aria-hidden");
                if(ariaHidden == "true") {
                    target.setAttribute("aria-hidden", false);
                } else {
                    target.setAttribute("aria-hidden", true);
                }
            };

            openModalBtn.addEventListener("click", toggleModal);
            closeBtn.addEventListener("click", toggleModal);
        }

        RWI.DownloadRapport();
    }

    setupRangeInput();

    updateChart(data);
}

function setupRangeInput() {
    var rangeSliders = [chartObj.amountInput, chartObj.periodInput, chartObj.percentInput];

    function showSliderValue(e) {
        let slider = e.target || e;
        let thumb = slider.parentNode.querySelector('span')
        let fill = slider.parentNode.querySelector('i')
        let thumbWidth = 18;
        var range = slider.max - slider.min;
        var position = ((slider.value - slider.min) / range) * 100;
        var positionOffset = Math.round(thumbWidth * position / 100) - (thumbWidth / 2);

        thumb.textContent = formatNumber(slider.value);
        thumb.style.left = 'calc(' + position + '% - ' + positionOffset + 'px)';
        fill.style.width = 'calc(' + position + '% - ' + positionOffset + 'px)';
    }

    for (let i = 0; i < rangeSliders.length; i++) {
        const rs = rangeSliders[i];

        showSliderValue(rs);
        rs.addEventListener("input", showSliderValue, false);

        rs.addEventListener("change", (e) => {
            updateChart()
        }, false);

        let rsMinMaxSpans = rs.parentNode.nextElementSibling.querySelectorAll("div span");
        if (rsMinMaxSpans) {
            for (let s = 0; s < rsMinMaxSpans.length; s++) {
                const span = rsMinMaxSpans[s];
                span.textContent = formatNumber(span.textContent);
            }
        }
    }

    window.matchMedia('print').addListener(function() {
        if(chart) {
            isPrint = true;
            updateChart();
        }
    });

    return rangeSliders;
}

function generatePDFlink() {
    const path = window.location.pathname;
    let url = window.location.origin + (path[path.length - 1] == '/' ? path.substr(0, path.length -1) : path);
        url += `?amount=${chartObj.amount}&years=${chartObj.years}&percent=${chartObj.percent}`;
    const downloadForm = document.getElementById('download-rapport-form');
    const encodedUrl = encodeURIComponent(url);

    downloadForm.setAttribute("data-pdflink", `https://pdf-generator.by.gotcha.dk?url=${encodedUrl}`);
}
