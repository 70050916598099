import {setupFilters} from './modules/filter';
import {createChart, createChartCalculator} from "./modules/charts";
import { initVhUnitOverwrite } from './utils/vh';
import { setupVideos } from './modules/video';


window.RWI = {};


RWI.GetScreenDimensions = function () {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    return { width: x, height: y };
};

RWI.SimulateClick = function (elem) {
    var evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    });
    var canceled = !elem.dispatchEvent(evt);
};

RWI.ScrollToElement = function (elm) {

    elm.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
};

RWI.ScrollDetect = function () {

    var lastScroll = 0;
    var lastDirection = 'down';

    var viewport = RWI.GetScreenDimensions();
    var isSmall = viewport.width < 768 || viewport.height < 600;
    var thresholdUp = isSmall ? 500 : 300; // how long to scroll up before we react?
    var thresholdDown = 100; // how long to scroll down before we react?
    //var gutter = viewport.height; // ignore any scrolling below this


    var scroll = function () {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        var currentDirection = lastDirection;

        if (currentScroll === 0) {
            lastScroll = 0;
            lastDirection = 'down';
            document.body.classList.remove('scrolling-up');
            return;
        }
        /* else if (currentScroll < gutter) {
            if (RWI.hasOwnProperty('CurrentVideoHero')) {
                if (RWI.CurrentVideoHero.paused === true) {
                    RWI.CurrentVideoHero.player.play();
                    RWI.CurrentVideoHero.paused = false;
                }
            }
            return;
        }
        else if (currentScroll > gutter) {
            if (RWI.hasOwnProperty('CurrentVideoHero')) {
                if (RWI.CurrentVideoHero.paused === false) {
                    RWI.CurrentVideoHero.player.pause();
                    RWI.CurrentVideoHero.paused = true;
                }
            }
        } */

        var difference = currentScroll - lastScroll;
        if (difference > 0 && difference >= thresholdDown || difference <= 0 && difference * -1 >= thresholdUp) {

            if (difference < 0) {
                currentDirection = 'up';
            } else {
                currentDirection = 'down';
            }

            lastScroll = currentScroll;
            if (currentDirection !== lastDirection) {

                if (currentDirection === 'up') {
                    document.body.classList.add('scrolling-up');
                }
                else {
                    document.body.classList.remove('scrolling-up');
                }

                lastDirection = currentDirection;
            }
        }
    };

    var init = function () {
        window.addEventListener('scroll', scroll);
    };

    init();
};

RWI.Ajax = {

    Get: function (url, success) {
        var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
        xhr.open('GET', url);
        xhr.onreadystatechange = function () {
            if (xhr.readyState > 3 && xhr.status === 200) {
                success(xhr.responseText);
            }
        };
        xhr.send();
        return xhr;
    },

    mailChimp: function (url, data, callback) {
        var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

        // Define callback
        xhr.onreadystatechange = function () {
            if (xhr.readyState > 3 && xhr.status === 200) {
                callback(xhr.responseText);
            }
        };

        // Set up our request
        xhr.open('POST', url, true);
        // Add the required HTTP header for form data POST requests
        xhr.setRequestHeader("Content-type", "application/json");
        // Finally, send our data.
        xhr.send(data);
        return xhr;
    },

    Post: function (url, data, callbackHandler) {
        var params = typeof data === 'string' ? data : Object.keys(data).map(
            function (k) { return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]); }
        ).join('&');

        var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
        xhr.open('POST', url);
        xhr.onreadystatechange = function () {
            if (xhr.readyState > 3 && xhr.status === 200) {
                callbackHandler(JSON.parse(xhr.responseText));
            }
        };
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(params);
        return xhr;
    }
};

RWI.FormHandler = function (form, submitHandler) {

    var isFormFieldFilled = function (e) {
        var fld = this;
        fld.classList.toggle('filled', fld.value !== '');
    };

    var isFormFieldChecked = function (e) {
        var fld = this;
        fld.classList.toggle('checked', fld.checked);
    };

    var init = function () {
        if (!form) {
            return;
        }

        if (submitHandler) {
            form.onsubmit = submitHandler;
        }

        var fieldList = form.querySelectorAll('input, textarea');
        for (var i = 0; i < fieldList.length; i++) {
            var field = fieldList[i];
            var tag = field.nodeName.toLowerCase();
            if (tag === 'input') {
                var type = field.getAttribute('type');
                if (type === 'checkbox')
                    field.addEventListener('click', isFormFieldChecked);
                else if (type !== 'hidden')
                    field.addEventListener('blur', isFormFieldFilled);
            } else if (tag === 'textarea') {
                field.addEventListener('blur', isFormFieldFilled);
            }
        }
    };

    var reset = function () {
        var field, s = [];
        if (typeof form === 'object' && form.nodeName === "FORM") {
            var len = form.elements.length;
            for (var i = 0; i < len; i++) {
                field = form.elements[i];
                if (field.name && field.type !== 'hidden' && field.type !== 'submit' && field.type !== 'button') {
                    if (field.type == 'checkbox') {
                        field.removeAttribute('checked');
                        field.classList.remove('checked');
                    }
                    else {
                        field.value = '';
                        field.classList.remove('filled');
                    }
                }
            }
        }
    };

    init();

    return {
        serialize: function (form) {
            var field, s = [];
            if (typeof form === 'object' && form.nodeName === "FORM") {
                var len = form.elements.length;
                for (var i = 0; i < len; i++) {
                    field = form.elements[i];
                    if (field.name && !field.disabled && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit' && field.type !== 'button') {
                        if (field.type === 'select-multiple') {
                            for (j = form.elements[i].options.length - 1; j >= 0; j--) {
                                if (field.options[j].selected)
                                    s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                            }
                        } else if (field.type !== 'checkbox' && field.type !== 'radio' || field.checked) {
                            s[s.length] = encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
                        }
                    }
                }
            }
            return s.join('&').replace(/%20/g, '+');
        },
        showConfirmationText: function () {
            var section = form.parentElement.parentElement;
            section.classList.add('form-submitted');
            section.querySelector('.confirmation-text').scrollIntoView();
            window.setTimeout(function () {
                reset();
                section.classList.remove('form-submitted');
            }, 4000);
        },
        showConfirmationPage: function () {
            if (form.getAttribute('data-confirmation-page') != '') {
                window.location = form.getAttribute('data-confirmation-page');
            }
        },
        resetForm: function () {
            reset();
        },
        form: form
    };
};

RWI.Newsletter = function () {

    var submitHandler = function () {
        var termsAccepted = document.querySelector('li.check-box input').checked;
        if (!termsAccepted) {
            alert('Accepter venligst betingelserne i vores privatlivspolitik');
            return false;
        }
    };

    var init = function () {
        var form = document.getElementById('newsletter-subscription-form');
        if (form !== null) {
            new RWI.FormHandler(form, submitHandler);
        }
    };

    init();
};

RWI.ContactForm = function () {

    var _formHandler = null;

    var submitCallbackHandler = function (json) {

        if (json.status != 'ok') {
            alert('Der opstod desværre en fejl under indsendelse af formularen.');
        }
        else {
            //_formHandler.showConfirmationText();
            _formHandler.showConfirmationPage();
        }
    };

    var submitHandler = function () {
        var termsAccepted = document.getElementById('cb-terms').checked;
        if (!termsAccepted) {
            alert('Accepter venligst betingelserne i vores privatlivspolitik');
        } else {
            var data = _formHandler.serialize(this);
            RWI.Ajax.Post('/umbraco/api/mail/sendmultinestedcontent', data, submitCallbackHandler);
        }
        return false;
    };

    var init = function () {
        var form = document.getElementById('contact-form');
        if (form !== null) {
            _formHandler = new RWI.FormHandler(form, submitHandler);
        }
    };

    init();
};

RWI.EventRegistrationForm = function () {

    var _formHandler = null;

    var submitCallbackHandler = function (json) {

        if (json.status != 'ok') {
            alert('Der opstod desværre en fejl under indsendelse af formularen.');
            console.log('Form error', json.message);
        }
        else {
            var wrap = _formHandler.form.parentElement;
            var section = wrap.parentElement;
            section.classList.add('form-submitted');
            wrap.scrollIntoView();
            setTimeout(function () {
                section.classList.remove('form-submitted');
                _formHandler.resetForm();
            }, 6000);
        }
    };

    var submitHandler = function () {
        var termsAccepted = document.getElementById('cb-terms').checked;
        if (!termsAccepted) {
            alert('Accepter venligst betingelserne i vores privatlivspolitik');
        } else {
            var data = _formHandler.serialize(this);
            RWI.Ajax.Post('/umbraco/api/mail/sendmultinestedcontent', data, submitCallbackHandler);
        }
        return false;
    };

    var init = function () {
        var form = document.getElementById('registration-form');
        if (form !== null) {
            _formHandler = new RWI.FormHandler(form, submitHandler);
        }
    };

    init();
};

RWI.DownloadProspectForm = function () {

    var _formHandler = null;

    var submitCallbackHandler = function (json) {

        if (json.status != 'ok') {
            alert('Der opstod desværre en fejl under indsendelse af formularen.');
            console.log('Form error', json.message);
        }
        else {
            _formHandler.showConfirmationText();
        }
    };

    var submitHandler = function () {
        var termsAccepted = document.getElementById('cb-terms').checked;
        if (!termsAccepted) {
            alert('Accepter venligst betingelserne i vores privatlivspolitik');
        } else {
            var data = _formHandler.serialize(this);
            RWI.Ajax.Post('/umbraco/api/mail/sendmultinestedcontent', data, submitCallbackHandler);
        }
        return false;
    };

    var init = function () {
        var form = document.getElementById('download-prospect-form');
        if (form !== null) {
            _formHandler = new RWI.FormHandler(form, submitHandler);
        }
    };

    init();
};

RWI.DownloadRapport = function() {
    var _formHandler;
    var form = document.getElementById('download-rapport-form');

    var submitCallbackHandler = function (data) {
        var response = JSON.parse(data);

        if (response.status != 'ok') {
            alert('Der opstod desværre en fejl under indsendelse af formularen.');
            console.warn('Form error', data.message);
        }
        else {
            window.location = form.getAttribute("data-pdflink");
            form.classList.add('form--submitting');

            var sendMailData = {
                firstname: form.querySelector('[name="NAME"]').value,
                lastname: form.querySelector('[name="LAST_NAME"]').value,
                email: form.querySelector('[name="EMAIL"]').value,
                phone: form.querySelector('[name="PHONE"]').value,
                terms: document.getElementById("gdpr_63").value,
                investment: window.chartObj.amount + window.chartObj.moneyUnit,
                period: window.chartObj.years,
                percent: window.chartObj.percent,
                system_umbracoTemplateNodeId: form.querySelector('[name="system_umbracoTemplateNodeId"]').value,
                system_umbracoTemplateNestedContentPropertyAlias: form.querySelector('[name="system_umbracoTemplateNestedContentPropertyAlias"]').value
            };

            RWI.Ajax.Post('/umbraco/api/mail/send', sendMailData, () => {
                setTimeout(() => {
                    form.classList.remove('form--submitting');
                    document.getElementById("chart-form").setAttribute('aria-hidden', true);
                    _formHandler.resetForm();
                }, 2000);
            });
        }
    };

    var submitHandler = function (e) {
        var requiredChecks = form.querySelectorAll('input[type=checkbox][required]')
        var termsAccepted = requiredChecks[1].checked;
        // Stops the forms native submit action
        e.preventDefault();

        if (!termsAccepted) {
            var requeiredMsg = requiredChecks[1].getAttribute("data-required-msg")
            if(requeiredMsg) {
                alert(requeiredMsg);
            } else {
                alert('Accepter venligst betingelserne i vores privatlivspolitik');
            }
        } else {
            var formData = new FormData(this);
            var subscriberName = formData.get('NAME') != null ? formData.get('NAME') : '';
            var subscriberEmail = formData.get('EMAIL') != null ? formData.get('EMAIL') : '';
            var subscriberLastName = formData.get('LAST_NAME') != null ? formData.get('LAST_NAME') : '';
            var subscriberPhone = formData.get('PHONE') != null ? formData.get('PHONE') : '';
            var aquisition = formData.get('AQUISITION') != null ? formData.get('AQUISITION') : '';
            var country = formData.get('COUNTRY') != null ? formData.get('COUNTRY') : '';
            var url = "/umbraco/api/mailchimp/addorupdatelistmember";
            var listId = form.getAttribute('data-mailchimp-id') || '8d24f2ad12';

            var data = JSON.stringify({
                'dataCenter': 'us7',
                'listId': listId,
                'subscriberEmail': subscriberEmail,
                'subscriberName': subscriberName,
                'subscriberLastName': subscriberLastName,
                'subscriberPhone': subscriberPhone,
                'aquisition': aquisition,
                'country': country
            });

            RWI.Ajax.mailChimp(url, data, submitCallbackHandler);
        }
        return false;
    };

    var init = function () {

        if (form !== null) {
            _formHandler = new RWI.FormHandler(form, submitHandler, true);
        }
    };

    init();
};

RWI.NewsSlider = function () {

    var newsSection = null;
    var nextLink = null;
    var previousLink = null;
    var nextLinkWrap = null;
    var previousLinkWrap = null;

    var goto = function (item, setHash) {
        if (item !== null) {
            var currentItem = newsSection.querySelector('.current-item');
            currentItem.classList.remove('current-item');
            item.classList.add('current-item');
            var nextSibling = item.nextElementSibling;
            var previousSibling = item.previousElementSibling;
            if (nextSibling !== null) {
                nextLinkWrap.style.backgroundImage = nextSibling.style.backgroundImage;
            }
            if (previousSibling !== null) {
                previousLinkWrap.style.backgroundImage = previousSibling.style.backgroundImage;
            }
            newsSection.classList.toggle('has-next', nextSibling !== null);
            newsSection.classList.toggle('has-previous', previousSibling !== null);

            if (setHash === true) {
                location.hash = '#newsitem-' + item.getAttribute('data-newsitemid');
            }
        }
    };

    var previous = function (e) {

        var currentItem = newsSection.querySelector('.current-item');
        var previousItem = currentItem.previousElementSibling;
        goto(previousItem, true);
        e.preventDefault();
        return false;
    };

    var next = function (e) {
        var currentItem = newsSection.querySelector('.current-item');
        var nextItem = currentItem.nextElementSibling;
        goto(nextItem, true);
        e.preventDefault();
        return false;
    };

    var updateSwipePaging = function () {
        var currentIndex = window.mySwipe.getPos();
        var pagingLinks = newsSection.querySelectorAll('.news-paging a');
        for (var i = 0; i < pagingLinks.length; i++) {
            var link = pagingLinks[i];
            if (i === currentIndex)
                link.classList.add('current');
            else
                link.classList.remove('current');
        }
    };

    var swipePagingClickHandler = function (e) {
        var index = this.getAttribute('data-index');
        e.preventDefault();
        window.mySwipe.slide(index, 200);
        return false;
    };

    var init = function () {
        newsSection = document.getElementById('news-section');
        if (newsSection === null) return;

        if (RWI.GetScreenDimensions().width <= 768) {
            var navDivs = document.querySelectorAll('#news-section > div');
            for (var i = 0; i < navDivs.length; i++)
                navDivs[i].remove();

            window.mySwipe = new Swipe(newsSection, {
                startSlide: 0,
                speed: 400,
                draggable: true,
                continuous: true,
                disableScroll: false,
                stopPropagation: true,
                callback: function (index, elem, dir) { },
                transitionEnd: function (index, elem) { updateSwipePaging(); }
            });

            var newsCount = newsSection.getElementsByTagName('li').length;
            var newsHeadlines = newsSection.querySelectorAll('li h2');
            var pagingWrap = document.createElement('DIV');
            pagingWrap.classList.add('news-paging');
            var pagingLinks = [];
            for (var n = 0; n < newsCount; n++) {
                pagingLinks.push('<a href="#" data-index="' + n + '" title="' + newsHeadlines[n].innerText + '"></a>');
            }
            pagingWrap.innerHTML = pagingLinks.join('');

            for (n = 0; n < pagingWrap.childNodes.length; n++) {
                pagingWrap.childNodes[n].addEventListener('click', swipePagingClickHandler);
            }

            newsSection.append(pagingWrap);
            updateSwipePaging();
        }
        else
        {
            previousLink = newsSection.querySelector('a.previous-link');
            previousLinkWrap = previousLink.parentNode;
            previousLink.addEventListener('click', previous);

            nextLink = newsSection.querySelector('a.next-link');
            nextLinkWrap = nextLink.parentNode;
            nextLink.addEventListener('click', next);

            var hash = location.hash;
            if (hash && hash !== '' && hash.indexOf('#newsitem-') > -1) {
                var itemId = hash.replace('#newsitem-', '');
                var item = newsSection.querySelector('li.news-item[data-newsitemid="' + itemId + '"]:not(.current-item)');
                if (item !== null) {
                    goto(item, false);
                }
            }
        }

    };

    init();
};

RWI.NewsList = function (settings) {
    var currentNewsItemsBatch = 1;
    var maxNewItemsBatch = settings.maxBatchCount || 1;

    var tagLinksSelector = 'ul.tag-list a';
    var tagLinks = null;
    var loadMore = document.getElementById('load-more');

    var loadMoreClick = function (e) {
        var parsedHash = parseHash();
        var newHash = currentNewsItemsBatch + 1;
        if (parsedHash.hasTag) {
            newHash += '/' + parsedHash.tag;
        }

        this.setAttribute('href', '#' + newHash);

        return false;
    };

    if (loadMore !== null) {
        loadMore.addEventListener('click', loadMoreClick);
    }

    var loadAllCurrentNewsItemBatches = function () {
        for (var i = 0; i <= currentNewsItemsBatch; i++) {
            loadNewsItemBatch(i);
        }
    };

    var loadNewsItemBatch = function (batch) {
        var coll = document.querySelectorAll('.news-item-batch-' + batch);
        for (var i = 0; i < coll.length; i++) {
            coll[i].classList.remove('hidden');
        }

        if (batch >= maxNewItemsBatch) {
            var loadMoreWrap = document.getElementById('load-more-wrap');
            if (loadMoreWrap != null)
                loadMoreWrap.remove();
        }
    };

    var tagLinkClick = function (e) {
        setActiveLink(this);
        var tag = this.getAttribute('href').replace('#', '');
        if (tag.indexOf('/') > -1)
            tag = tag.substring(tag.indexOf('/') + 1);

        applyTag(tag);
        this.setAttribute('href', '#' + currentNewsItemsBatch + '/' + tag);
    };

    var setActiveLink = function (lnk) {
        var active = document.querySelector(tagLinksSelector + '.active');
        active.classList.remove('active');
        lnk.classList.add('active');
    };

    var applyTag = function (tag) {
        var newsSection = document.querySelector('section.news-list-section');
        var newsListItems = document.querySelectorAll('ul.news-list > li');
        var maxOrder = newsListItems.length + 1;
        var order = 1;
        var i, item;
        for (i = 0; i < newsListItems.length; i++) {
            item = newsListItems[i];
            item.classList.add('filter-hide');
        }

        setTimeout(function () {
            for (i = 0; i < newsListItems.length; i++) {
                item = newsListItems[i];
                item.style.order = 'initial';

                if (tag == 'Alle')
                {
                    item.classList.remove('filter-hide');
                }
                else
                {
                    var tags = item.getAttribute('data-tags') || '';
                    if (tags.indexOf(tag + ',') === -1) {
                        item.style.order = maxOrder;
                    } else {
                        item.classList.remove('filter-hide');
                        item.style.order = order;
                        order++;
                    }
                }
            }
        }, 10);

    };

    var parseHash = function () {
        var hash = location.hash || '';
        hash = hash.replace('#', '');
        var hasHash = hash != '';

        var page = 1;
        if (hasHash) {
            page = parseInt(hash.indexOf('/') > -1 ? hash.substring(0, hash.indexOf('/')) : hash);
        }
        var tag = hash.indexOf('/') > -1 ? hash.substring(hash.indexOf('/') + 1) : null;

        return {
            hasHash: hasHash,
            page: page,
            tag: tag,
            hasTag: hasHash && tag && tag !== ''
        };
    };

    var hashChange = function ()
    {
        var parsedHash = parseHash();
        var isPageChange = parsedHash.page != currentNewsItemsBatch;
        if (isPageChange)
        {
            var isMultiplePageChange = parsedHash.page > (currentNewsItemsBatch + 1);
            currentNewsItemsBatch = parsedHash.page;


            if (isMultiplePageChange) {
                loadAllCurrentNewsItemBatches();
            }
            else {
                loadNewsItemBatch(currentNewsItemsBatch);
            }
        }

        // if (parsedHash.hasTag)
        // {
        //     var tagLink = document.querySelector(tagLinksSelector + '[href="#' + parsedHash.tag + '"]');
        //     if (tagLink !== null) {
        //         RWI.SimulateClick(tagLink);
        //     }
        // }
        // else
        // {
        //     var allTagLink = document.querySelector(tagLinksSelector + '[href="#Alle"]');
        //     setActiveLink(allTagLink);
        // }


    };

    var init = function () {
        window.addEventListener('hashchange', hashChange);

        // tagLinks = document.querySelectorAll(tagLinksSelector);
        // for (var i = 0; i < tagLinks.length; i++) {
        //     tagLinks[i].addEventListener('click', tagLinkClick);
        // }

        hashChange();
    };

    init();
};

RWI.TableDecorator = function () {
    var tables = document.querySelectorAll('.news-item-content.table table');
    if (!tables) return;
    for (var i = 0; i < tables.length; i++) {
        var table = tables[i];
        var labels = [];
        var headerCells = table.querySelectorAll('thead th');
        for (var j = 0; j < headerCells.length; j++) {
            labels.push(headerCells[j].innerHTML);
        }
        var rows = table.querySelectorAll('tbody tr');
        for (var k = 0; k < rows.length; k++) {
            var row = rows[k];
            for (var l = 0; l < row.cells.length; l++) {
                row.cells[l].setAttribute('data-label', labels[l]);
            }
        }
    }
};

RWI.CookieConsent = function (settings) {

    var cookieName = settings.cookieName || 'CookieConsent';
    var cookieNoticeSelector = settings.cookieNoticeSelector || '#cookie-notice';
    var cookieNotice = document.querySelector(cookieNoticeSelector);

    var setConsentCookie = function () {
        docCookies.setItem(cookieName, "Accepted", Infinity, '/');
        document.body.classList.remove('with-cookie-notice');
    };

    if (!docCookies.hasItem(cookieName)) {
        if (cookieNotice) {
            cookieNotice.querySelector('button').addEventListener('click', setConsentCookie);
            setTimeout(function () { document.body.classList.add('with-cookie-notice'); }, 2500);
        }
    }
};

RWI.Charts = {};

RWI.Charts.Create = createChart;
RWI.Charts.createChartCalculator = createChartCalculator;

/* RWI.VideoHero = function () {

    var video = null;

    var playerScriptAdded = function () {
        if (video === null) return;
        var player = new Vimeo.Player(video);
        if (player !== null) {
            player.setVolume(0);
            player.play();
            RWI.CurrentVideoHero = {
                player: player,
                paused: false
            };

            //// debug
            //player.on('play', function () {
            //    console.log('played video');
            //});
            //player.on('pause', function () {
            //    console.log('paused video');
            //});
        }
    };

    var addPlayerScript = function () {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://player.vimeo.com/api/player.js');
        s.onload = playerScriptAdded;
        document.body.appendChild(s);
    };

    var init = function () {
        video = document.querySelector('section.hero iframe.video');
        if (video !== null) {
            addPlayerScript();
        }
    };

    init();
}; */

function setupLanguageSelector () {
    const languageSelector = document.querySelector('.language-selector');
    if (languageSelector) {
        const languageSelectorList = languageSelector.querySelector('.language-selector__list');
        if (languageSelectorList) {

            languageSelector.addEventListener('click', function () {
                if (languageSelector.getAttribute('aria-expanded').toLowerCase() == 'false') {
                    languageSelectorList.style.height = `${languageSelectorList.scrollHeight}px`;

                    languageSelectorList.addEventListener('transitionend', function(e) {
                        if (e.propertyName === 'height') {
                            languageSelectorList.style.height = null;
                        }
                    });

                    languageSelectorList.setAttribute('aria-hidden', false);
                    languageSelector.setAttribute('aria-expanded', true);
                }
                else {
                    languageSelectorList.style.height = `${languageSelectorList.scrollHeight}px`;

                    setTimeout(() => {
                        languageSelectorList.style.height = '0';
                    }, 0);

                    languageSelectorList.setAttribute('aria-hidden', true);
                    languageSelector.setAttribute('aria-expanded', false);
                }
            });
        }
    }

}

document.addEventListener('DOMContentLoaded', function () {

    void new LazyLoad({
        elements_selector: ".lazy",
        threshold: 700
    });

    AOS.init({ duration: 900 });

    //new RWI.VideoHero();
    new RWI.ScrollDetect();
    new RWI.Newsletter();
    new RWI.NewsSlider();

    // scroll button click
    var scrollButtons = document.getElementsByClassName('scroll-btn');
    if (scrollButtons && scrollButtons.length > 0) {
        scrollButtons[0].addEventListener('click', function (e) {
            var firstSection = document.getElementsByTagName('section')[1];
            e.preventDefault();
            RWI.ScrollToElement(firstSection);
            return false;
        });
    }

    // nav burger click
    document.getElementById('nav-trigger').addEventListener('click', function (e) {
        document.body.classList.toggle('with-nav');
        e.preventDefault();
        setTimeout(function () {
            document.body.classList.toggle('blurred');
        }, 250);
        return false;
    });

    initVhUnitOverwrite();

    // rellax
    var useRellax = false;

    if ('ontouchstart' in window) {
        document.body.classList.add('touch');
        if (RWI.GetScreenDimensions().width > 768) {
            useRellax = true;
        }
    } else {
        document.body.classList.add('no-touch');
        useRellax = true;
    }

    if (useRellax && document.querySelectorAll('.rellax').length > 0) {
        this.Rellax = new Rellax();
    }

    new RWI.DownloadProspectForm();

    MediaBox('.mediabox');

    setupFilters();

    setupVideos();

    setupLanguageSelector();
});
