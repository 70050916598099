/**
 * Combined lightweight vimeo/youtube embed code.
 *
 * Ported from https://github.com/paulirish/lite-youtube-embed by Paul Irish
 * and https://github.com/luwes/lite-vimeo-embed by Wesley Luyten
 *
 */
class Video {
    constructor(container) {
        this.container = container;
        this.id = container.getAttribute('data-id');
        this.controls = container.getAttribute('data-controls') || 1,
        this.bg = container.getAttribute('data-bg') || 0,
        this.byline = container.getAttribute('data-byline') || 0;
        this.scrollto = container.getAttribute('data-scroll-to') || 0;

        let videoLink;

        if (!this.id) {
            videoLink = container.getAttribute('data-video-link');
            if (videoLink) {
                this.id = extractVideoIdFromUrl(videoLink);
            } else {
                console.warn('Video missing video-id / data-video-link');
                return;
            }
        }

        this.isVimeo = container.getAttribute('data-action') === 'video-vimeo' || videoLink && videoLink.indexOf('vimeo') > -1;
        this.poster = container.getAttribute('data-poster');

        if (!this.poster) {
            if (this.isVimeo) {

                let { width, height } = getRoundedDimensions(container.getBoundingClientRect());
                const devicePixelRatio = window.devicePixelRatio || 1;
                width *= devicePixelRatio;
                height *= devicePixelRatio;
                /**
                 * To get the vimeo poster image, we have to use the Vimeo API.
                 */
                this.poster = `https://lite-vimeo-embed.now.sh/thumb/${this.id}`;
                this.poster += `.${canUseWebP() ? 'webp' : 'jpg'}`;
                this.poster += `?mw=${width}&mh=${height}&q=${devicePixelRatio > 1 ? 70 : 85}`;

            } else {
                /**
                 * Comment about the thumbnail, by original author: Paul Irish
                 * Lo, the youtube placeholder image!  (aka the thumbnail, poster image, etc)
                 * There is much internet debate on the reliability of thumbnail URLs. Weak consensus is that you
                 * cannot rely on anything and have to use the YouTube Data API.
                 *
                 * amp-youtube also eschews using the API, so they just try sddefault with a hqdefault fallback:
                 *   https://github.com/ampproject/amphtml/blob/6039a6317325a8589586e72e4f98c047dbcbf7ba/extensions/amp-youtube/0.1/amp-youtube.js#L498-L537
                 * For now I'm gonna go with this confident (lol) assertion: https://stackoverflow.com/a/20542029, though I'll use `i.ytimg` to optimize for origin reuse.
                 *
                 * Worth noting that sddefault is _higher_ resolution than hqdefault. Naming is hard. ;)
                 * From my own testing, it appears that hqdefault is ALWAYS there sddefault is missing for ~10% of videos*/

                this.poster = `https://i.ytimg.com/vi/${this.id}/sddefault.jpg`;
            }
        }

        this.container.style.backgroundImage = `url("${this.poster}")`;


        if (!!this.bg) {
            Video.warmConnections();
            this.addIframe();
        } else {
            // On hover (or tap), warm up the TCP connections we're (likely) about to use.
            this.container.addEventListener('pointerover', Video.warmConnections, { once: true });

            // Once the user clicks, add the real iframe and drop our play button
            // TODO: In the future we could be like amp-youtube and silently swap in the iframe during idle time
            //   We'd want to only do this for in-viewport or near-viewport ones: https://github.com/ampproject/amphtml/pull/5003
            this.container.addEventListener('click', () => this.addIframe());
        }
    }

    /**
     * Begin pre-connecting to warm up the iframe load
     * Since the embed's network requests load within its iframe,
     *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
     * So, the best we can do is warm up a few connections to origins that are in the critical path.
     *
     * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
     * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
     */
    static warmConnections() {
        if (Video.preconnected) return;

        if (this.isVimeo) {
            // The iframe document and most of its subresources come right off player.vimeo.com
            addPrefetch('preconnect', 'https://player.vimeo.com');
            // Images
            addPrefetch('preconnect', 'https://i.vimeocdn.com');
            // Files .js, .css
            addPrefetch('preconnect', 'https://f.vimeocdn.com');
            // Metrics
            addPrefetch('preconnect', 'https://fresnel.vimeocdn.com');
        } else {
            // The iframe document and most of its subresources come right off youtube.com
            addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
            // The botguard script is fetched off from google.com
            addPrefetch('preconnect', 'https://www.google.com');
            // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
            addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
            addPrefetch('preconnect', 'https://static.doubleclick.net');
        }

        Video.preconnected = true;
    }

    addIframe() {
        let videoUrl = this.isVimeo ? `https://player.vimeo.com/video/${this.id}?autoplay=1&rel=0` : `https://www.youtube-nocookie.com/embed/${this.id}?autoplay=1&rel=0`;

        const iframe = document.createElement('iframe');
        iframe.setAttribute('frameborder', 0);
        iframe.setAttribute('allowtransparency', 'true');
        iframe.setAttribute('playsinline', 'true');
        iframe.setAttribute('tabindex', '-1');
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; autoplay');
        iframe.addEventListener('load', () => {
            // Timeout is set to prevent paint-lag when iframe is inserted
            setTimeout(() => {
                this.container.classList.add('video--loaded');
                this.container.classList.remove('loading');
            }, 300);
        });

        videoUrl += `&controls=${this.controls}&byline=${this.byline}&background=${this.bg}&mute=${this.bg}&color=222851`;

        iframe.src = videoUrl;

        this.container.classList.add('loading');
        this.container.appendChild(iframe);
    }
}


function extractVideoIdFromUrl(url) {
    const videoIdRegExp = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(videoIdRegExp);

    if (match && match[7]) {
        return match[7];
    } else {
        console.warn(url, 'Invalid Video URL');
    }
}

/**
 * Initialise video embeds with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupVideos(selector = '[data-action^="video"]') {
    const videos = document.body.querySelectorAll(selector);

    for (let i = 0; i < videos.length; i++) {
        void new Video(videos[i]);
    }
}

function canUseWebP() {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

/**
 * Add a <link rel={preload | preconnect} ...> to the head
 */
function addPrefetch(kind, url, as) {
    const linkElem = document.createElement('link');
    linkElem.rel = kind;
    linkElem.href = url;
    if (as) {
        linkElem.as = as;
    }
    linkElem.crossorigin = true;
    document.head.append(linkElem);
}

/**
 * Get the thumbnail dimensions to use for a given player size.
 *
 * @param {Object} options
 * @param {number} options.width The width of the player
 * @param {number} options.height The height of the player
 * @return {Object} The width and height
 */
function getRoundedDimensions({ width, height }) {
    let roundedWidth = width;
    let roundedHeight = height;

    // If the original width is a multiple of 320 then we should
    // not round up. This is to keep the native image dimensions
    // so that they match up with the actual frames from the video.
    //
    // For example 640x360, 960x540, 1280x720, 1920x1080
    //
    // Round up to nearest 100 px to improve cacheability at the
    // CDN. For example, any width between 601 pixels and 699
    // pixels will render the thumbnail at 700 pixels width.
    if (roundedWidth % 320 !== 0) {
        roundedWidth = Math.ceil(width / 100) * 100;
        roundedHeight = Math.round((roundedWidth / width) * height);
    }

    return {
        width: roundedWidth,
        height: roundedHeight
    };
}
