
/**
 * Add an array of numbers together.
 *
 * @example
 * import { addition } from "./utils/calc.js";
 * const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
 * const total = addition(numbers) // 45
 *
 * @param {Number[]} numbers - Array of numbers to add together
 * @returns {Number}
 */
export function addition(numbers) {
    return numbers.reduce((a, b) => a + b, 0);
}

/**
 * Get the avrage from an array of numbers.
 *
 * All the values are added together and divided by the amount of numbers.
 *
 * @example
 * import {getAvarage} from "./utils/calc.js";
 *
 * const numbers = [4, 8, 15, 16, 23, 42];
 * const avarage = getAvarage(numbers); // 18
 *
 * @param {Number[]} numbers - Array of numbers to find the Avarage of
 * @returns {Number}
 */
export function getAvarage(numbers) {
    return addition(numbers) / numbers.length;
}