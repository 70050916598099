
const classNames = {
    activeBtn: 'filter__tab--active',
    hiddenItem: 'hidden',
    searchActive: 'filter--search-active'
};

/**
 *
 * @param {HTMLElement} container
 *
 * @constructor
 */
export class Filter {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container - Element containing filter options (Could be .filter__tabs or .filter__select)
     */
    constructor(container) {
        if (container) {
            this.dom = {
                container,
                filters: container.querySelectorAll('[data-filter]'),
                targetContainer: document.body.querySelector(container.getAttribute('data-target-container')),
                button: container.querySelector('.filter__btn'),
                loadmoreBtn: document.getElementById('load-more'),
                searchInput: container.querySelector('.filter__search__input')
            };

            this.settings = {
                filterType:  container.getAttribute('data-action'),
                date:  container.getAttribute('date-filter-date')
            };

            const urlParams = new URLSearchParams(window.location.search).get('filterValues');
            const urlSearchParam = new URLSearchParams(window.location.search).get('search');
            let urlQuery = '';

            if (urlParams) {
                urlQuery = urlParams.split(',');
            }

            if (urlSearchParam) {
                this.dom.searchInput.value = urlSearchParam;
            }

            if (this.dom.targetContainer) {
                for (let i = 0; i < this.dom.filters.length; i++) {
                    const filter = this.dom.filters[i];
                    const itemCountReplace = filter.getAttribute('data-item-count-replace');

                    if (filter.tagName === "BUTTON") {
                        filter.addEventListener('click', e => this.filterHandler(e));
                    } else {
                        filter.addEventListener('change', e => this.filterHandler(e));

                        if (itemCountReplace) {
                            this.replaceWithNumberOfItems(filter, itemCountReplace);
                        }
                    }

                    if (urlQuery) {
                        if (filter.tagName === 'BUTTON' || filter.type === 'radio') {
                            if (urlQuery.includes(filter.getAttribute('data-filter'))) {
                                filter.click();
                            }
                        } else {
                            for (let i = 0; i < filter.options.length; i++) {
                                const option = filter.options[i].value;

                                if (urlQuery.includes(option)) {
                                    filter.value = option;
                                    filter.dispatchEvent(new Event('change'));
                                }
                            }
                        }
                    }
                }

                if (urlSearchParam) {
                    this.handleSearchRequest();
                }

                if(this.dom.searchInput) {
                    this.dom.searchInput.addEventListener('keyup', e => this.handleSearchRequest(e));
                    this.dom.searchInput.addEventListener('blur', () => this.resetFilter(true,true));
                }
            } else {
                window.console.log("Can't find [data-target-container] on filter container: ", container);
            }

            if(this.dom.button) {
                this.dom.button.addEventListener('click', e => {
                    if(this.dom.button.classList.contains('active')) {
                        this.dom.button.classList.remove('active');
                    } else {
                        this.dom.button.classList.add('active');
                    }
                });
            }
        } else {
            window.console.log("Filter container missing");
        }
    }

    /**
     * Filter function that compares selected filter values with filter-values on filter-item.
     * Added hidden-class to items that hasn't got the selected filter value.
     *
     * @param {HTMLElement} e - event (click or change event
     */
    filterHandler(e) {
        const filter = e.target;
        const filterItems = this.dom.targetContainer.querySelectorAll('[data-filter-value]');
        const isButton = filter.tagName === "BUTTON";
        const filterValue = isButton ? filter.getAttribute('data-filter') : filter.value;
        const filterByDate = filter.getAttribute('data-filter-bydate') || null;
        const dateNow = this.settings.date ? Date.parse(this.settings.date) : "";

        if(this.dom.loadmoreBtn) {
            this.dom.loadmoreBtn.classList.add(classNames.hiddenItem);
            for (let i = 0; i < filterItems.length; i++) {
                filterItems[i].classList.remove(classNames.hiddenItem);
            }
        }

        if(this.dom.searchInput) {
            this.dom.searchInput.value = "";
        }

        if (isButton) {
            if (this.settings.filterType === "filter-switch") {
                for (let i = 0; i < this.dom.filters.length; i++) {
                    this.dom.filters[i].classList.remove(classNames.activeBtn);
                }

                filter.classList.add(classNames.activeBtn);
            } else {
                const filterValues = Filter.getSetValues(this.dom.filters);
                this.dom.targetContainer.classList.add(classNames.transitionItems);

                if (!filter.classList.contains(classNames.activeBtn)) {
                    filter.classList.add(classNames.activeBtn);
                } else {
                    filter.classList.remove(classNames.activeBtn);
                }
            }

            const filterValues = Filter.getSetValues(this.dom.filters);

            //updating url with the filter values
            const nextURL = `${location.protocol}//${location.host}${location.pathname}?filterValues=${filterValues}`;
            const nextTitle = document.title;
            const nextState = {
                additionalInformation: 'Updated the URL with JS'
            };
            window.history.replaceState(nextState, nextTitle, nextURL);
        }

        /* If the show all button os pressed
        *  Remove hidden class from loadMoreBtn and only show first batch */
        /*if(filterValue === "*") {

            if(this.dom.loadmoreBtn) {
                this.dom.loadmoreBtn.classList.remove(classNames.hiddenItem);
            }

            for (let i = 0; i < filterItems.length; i++) {
                let filterItem = filterItems[i];

                filterItem.classList.remove(classNames.hiddenItem);

                if(!filterItem.classList.contains('news-item-batch-1')) {

                    filterItem.classList.add(classNames.hiddenItem);
                } else {

                }
            }

            window.location.hash = '#1';

            return;
        }*/


        if (this.settings.filterType === "filter-switch") {
            for (let i = 0; i < filterItems.length; i++) {
                const item = filterItems[i];
                let itemFilterValues = item.getAttribute('data-filter-value').split(',');
                const cleanFilterValue = filterValue.trim().toLowerCase();
                itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                if(filterByDate) {

                    let itemDateInMs = Date.parse(itemFilterValues);

                    if(filterByDate === "passed") {
                        if(dateNow > itemDateInMs) {
                            item.classList.remove(classNames.hiddenItem);
                        } else {
                            item.classList.add(classNames.hiddenItem);
                        }
                    }
                } else if (filterValue !== '*' && itemFilterValues.indexOf(cleanFilterValue) === -1) {
                    item.classList.add(classNames.hiddenItem);
                } else {
                    item.classList.remove(classNames.hiddenItem);
                }
            }
        } else {
            const filterValues = Filter.getSetValues(this.dom.filters);

            for (let i = 0; i < filterItems.length; i++) {
                const item = filterItems[i];
                let itemFilterValues = item.getAttribute('data-filter-value').split(',');
                let showItem = true;

                itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                filterValues.forEach(value => {
                    const cleanValue = value.trim().toLowerCase();
                    if (itemFilterValues.indexOf(cleanValue) === -1) {
                        showItem = false;
                    }
                });

                if (!showItem) {
                    item.classList.add(classNames.hiddenItem);
                } else {
                    item.classList.remove(classNames.hiddenItem);
                }
            }
        }

    }

    /**
     * Checks filter items for matches in title and filter-values, if value has minimum number of characters
     *
     * @param {Event} e - Keyboard Event
     * @private
     */
    handleSearchRequest(e) {
        const value = this.dom.searchInput.value.trim();
        const filterItems = this.dom.targetContainer.querySelectorAll('[data-filter-value]');

        if (value.length >= 2) {
            // Prevent looping through items if ArrowKeys are pressed:
            if (e) {
                if (e.which >= 37 && e.which <= 40) { // 37 = ArrowLeft / 38 = ArrowUp / 39 = ArrowRight / 40 = ArrowDown
                    return;
                }
            }

            if(!this.dom.container.classList.contains(classNames.searchActive)) {
                this.resetFilter(false,true);
            }

            const regex = new RegExp(value, 'gi');

            for (let i = 0; i < filterItems.length; i++) {
                const item = filterItems[i];
                const itemFilterValues = item.getAttribute('data-filter-value');
                const itemTitle = item.querySelector('h2').innerText;

                if (itemFilterValues.match(regex) || itemTitle.match(regex)) {
                    item.classList.remove('hidden');
                } else {
                    item.classList.add(classNames.hiddenItem);
                }
            }
        } else {
            this.resetFilter(true);
            for (let i = 0; i < filterItems.length; i++) {
                filterItems[i].classList.remove(classNames.hiddenItem);
            }
        }
    }

    resetFilter(activate = false, addSearchQuery = false) {

        if (activate) {
            this.dom.container.classList.remove(classNames.searchActive);
        } else {
            this.dom.container.classList.add(classNames.searchActive);

            for (let i = 0; i < this.dom.filters.length; i++) {
                const filter = this.dom.filters[i];

                if (filter.getAttribute('data-filter') === "*") {
                    filter.classList.add(classNames.activeBtn);
                } else {
                    filter.classList.remove(classNames.activeBtn);
                }
            }
        }

        if (addSearchQuery) {
            //updating url with the filter values
            const nextURL = `${location.protocol}//${location.host}${location.pathname}?search=${this.dom.searchInput.value.trim()}`;
            const nextTitle = document.title;
            const nextState = {
                additionalInformation: 'Updated the URL with JS'
            };
            window.history.replaceState(nextState, nextTitle, nextURL);
        } else {
            const nextURL = `${location.protocol}//${location.host}${location.pathname}`;
            const nextTitle = document.title;
            const nextState = {
                additionalInformation: 'Updated the URL with JS'
            };
            window.history.replaceState(nextState, nextTitle, nextURL);
        }
    }

    static getSetValues(filters) {
        const valArray = [];

        // loop through list of filters
        for (let i  = 0, len = filters.length; i < len; i++) {
            const filter = filters[i];
            if (filter.tagName === "SELECT") {
                if (filter.value !== "*" && filter.value !== "") {
                    valArray.push(filter.value);
                }
            } else if (filter.tagName === "BUTTON") {
                if (filter.classList.contains(classNames.activeBtn)) {
                    valArray.push(filter.getAttribute('data-filter'));
                }
            } else {
                if (filter.checked && filter.value !== "*") { // radio checked?
                    valArray.push(filter.value); // if so, add its value to array
                }
            }
        }
        return valArray; // return value of checked radio or undefined if none checked
    }

    replaceWithNumberOfItems(element, replaceValue) {
        const options = element.children;
        const items = this.dom.targetContainer.querySelectorAll('[data-filter-value]');

        if (options) {
            for (let i = 0; i < options.length; i++) {
                const option = options[i];

                if (option.value !== "*" && option.value !== "") {
                    let count = 0;
                    const optionStr = option.innerText;

                    for (const item of items) {
                        let itemFilterValues = item.getAttribute('data-filter-value').split(',');

                        itemFilterValues = itemFilterValues.map(value => value.trim().toLowerCase());

                        const cleanValue = option.value.trim().toLowerCase();
                        if (itemFilterValues.indexOf(cleanValue) > -1) {
                            count++;
                        }
                    }

                    option.innerText = optionStr.replace(replaceValue, count);
                }
            }
        }
    }
}

export function setupFilters(selector = '[data-action*="filter"]') {
    const filterContainers = document.body.querySelectorAll(selector);

    for (let i = 0; i < filterContainers.length; i++) {
        void new Filter(filterContainers[i]);
    }
}
